export const Contact = (props) => {  
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8 col-md-offset-1 contact-info'>
            <div className='section-title'>
              <h2>Contato</h2>
            </div>
            <div className='contact-item'>
              <p className='contact-field'>
                <span>
                  <i className='fa fa-map-marker'></i> Endereço
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p className='contact-field'>
                <span>
                  <i className='fa fa-phone'></i> Telefone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p className='contact-field'>
                <span>
                  <i className='fa fa-whatsapp'></i> Whatsapp (exceto ACS)
                </span>{' '}                
                <a href="https://wa.me/32998071731?text=Bem vindo(a). Este número é para atendimento de todos os profissionais, exceto ACS." target="_blank" class="btn btn-wpp">{props.data ? props.data.wpp : 'loading'}</a>
              </p>
            </div>
            <div className='contact-item'>
              <p className='contact-field'>
                <span>
                  <i className='fa fa-whatsapp'></i> Whatsapp (exclusivo para ACS)
                </span>{' '}                
                <a href="https://wa.me/32988926307?text=Bem vindo(a). Este número é de atendimento exclusivo para ACS." target="_blank" class="btn btn-wpp">{props.data ? props.data.wppACS : 'loading'}</a>
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2021 Elo Assessoria e Sistemas Ltda.
          </p>
        </div>
      </div>
    </div>
  )
}
