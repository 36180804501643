export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <h2>Sobre</h2>
              <p>
                {props.data
                  ? props.data.Paragraph.map((text) => (
                    <p>{text}</p>
                  ))
                  : 'carregando...'}
              </p>
            </div>
          </div>

          <div className='col-xs-12 col-md-6'>
            <img src='img/notebook.jpg' className='img-responsive' alt='Imagem de um notebook com um software executando; ao mesmo tempo em volta do nosaindo feixes de luz em formas de ícones de usuário.' />{' '}

            <h3 style={{ marginTop: '10px' }}>POR QUE NOS CONTRATAR?</h3>
            <div className='list-style about-text'>
              <div className='col-lg-6 col-sm-6 col-xs-12'>
                <ul>
                  {props.data
                    ? props.data.Why.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    ))
                    : 'carregando...'}
                </ul>
              </div>
              <div className='col-lg-6 col-sm-6 col-xs-12'>
                <ul>
                  {props.data
                    ? props.data.Why2.map((d, i) => (
                      <li key={`${d}-${i}`}> {d}</li>
                    ))
                    : 'carregando...'}
                </ul>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}
