export function Maps() {
  return (
    <div id="maps">
      <div className="container-fluid">
        <div className="section-title text-center">
          <h2>Localização</h2>
        </div>
        <div className="row">
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.4816302179092!2d-44.247194948947495!3d-21.133222882236204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa1c62bb773be41%3A0xbe92173f35418778!2sElo%20Assessoria%20e%20Sistemas%20Ltda!5e0!3m2!1spt-BR!2sbr!4v1618576982746!5m2!1spt-BR!2sbr"'
            width="100%"
            height="600px"
            id="mapaElo"
            className="mapa"
            display="initial"
            position="relative"
            loading="lazy"
            title="map"
          />
        </div>
      </div>
    </div>
  );
}
